import { useRequest } from '@/composables/useRequest';
import { ApiMethod } from '@/enums/api.enum';
import type { CreatePrecessionRequest } from '@/types/purchase-condition/purchase-condition.request.type';
import type {
  PrecessionResponse,
  TermsRunningVersionResponse
} from '@/types/purchase-condition/purchase-condition.response.type';
import { camelToSnake } from '@/utils/api.util';

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+purchase+condition#6f905272-e585-4936-b23c-f4aa8e4da98c-456773628
export const fetchPrecessionApi = async (
  productNo: string
): Promise<PrecessionResponse | undefined> => {
  const res = await useRequest<PrecessionResponse>(`studio/v1/products/${productNo}/precession`, {
    method: ApiMethod.Get,
    showCommonError: false
  });

  if (res.status !== 0) {
    return;
  }

  return res?.data;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+purchase+condition#6f905272-e585-4936-b23c-f4aa8e4da98c-456774401
export const createPrecessionApi = async (
  productNo: string,
  data: CreatePrecessionRequest
): Promise<void> => {
  await useRequest<void>(`studio/v1/products/${productNo}/precession`, {
    method: ApiMethod.Post,
    data: camelToSnake(data),
    showCommonError: true
  });
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SDKAPIDOCU&title=WDB03.+studio+%7C+purchase+condition#6f905272-e585-4936-b23c-f4aa8e4da98c-456774975
export const updatePrecessionApi = async (
  productNo: string,
  data: CreatePrecessionRequest
): Promise<void> => {
  await useRequest<void>(`studio/v1/products/${productNo}/precession`, {
    method: ApiMethod.Put,
    data: camelToSnake(data),
    showCommonError: true
  });
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+terms+and+conditions+%7C+Get+list+all+running+version
export const fetchPrecessionVersionApi = async (
  gameId: string
): Promise<TermsRunningVersionResponse[] | undefined> => {
  const res = await useRequest<{ contents: TermsRunningVersionResponse[] }>(
    `studio/v1/product/${gameId}/terms/version/list-all-running`,
    {
      method: ApiMethod.Get,
      showCommonError: false
    }
  );
  return res?.data?.contents;
};
